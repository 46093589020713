import React, {FC, useEffect} from 'react';
import {Link} from 'gatsby';
import logo from '../../images/logo.svg';
import one from '../../images/one.png';
import two from '../../images/two.png';
import three from '../../images/three.png';
import './styles.scss';
import { getNewUser, getRawCookie, removeDocumentReferrer, removeImpactClickIdCookie, removeImpactMediaPartnerIdCookie, setDocumentReferrer, setImpactClickIdCookie, setImpactMediaPartnerIdCookie, setNewUser } from '../../helpers';
import CookieConsentPopup from '../cookie-popup';

const SimpleLayout: FC<any> = ({children, isClickable, noIllustration}) => {
  useEffect(() => {
    // Setting document referrer
    const referrer =
      typeof document !== 'undefined' &&
      document &&
      document.referrer &&
      document.referrer.indexOf('https://hexospark.com') === -1 &&
      document.referrer;
    if (referrer && getRawCookie('document_referrer')) {
      removeDocumentReferrer();
    }
    referrer && setDocumentReferrer(referrer);
    // Setting Impact irclickid in cookies
    const url = window.location.href;
    if (url && url.indexOf('irclickid=') > -1) {
      if (getRawCookie('__irClickId')) {
        removeImpactClickIdCookie();
      }
      const id = url.slice(url.indexOf('irclickid=') + 10);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactClickIdCookie(idFin);
      removeImpactMediaPartnerIdCookie();
    } else if (url && url.toLocaleLowerCase().indexOf('mediapartnerid=') > -1) {
      if (getRawCookie('__mediaPartnerId')) {
        removeImpactMediaPartnerIdCookie();
      }
      const id = url.slice(url.toLocaleLowerCase().indexOf('mediapartnerid=') + 15);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactMediaPartnerIdCookie(idFin);
      removeImpactClickIdCookie();
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes('PayerID=') || window.location.href.includes('&ba_token=')) {
      localStorage.setItem('payment_success', 'true');
      window.close();
    }
  }, []);

  useEffect(() => {
    if (getNewUser()) {
      if (window.location.href.includes('hexospark.com')) {
        //@ts-ignore
        window.dataLayer = [];
        //@ts-ignore
        window.dataLayer.push({
          event: `accountRegistration-hexospark`,
          registrationOrderId: new Date().toISOString(),
        });
        //@ts-ignore
        window.dataLayer.push({
          event: `registration-hexospark`,
          ecommerce: {
            registration: {
              actionField: {
                id: `Registration_${new Date().toISOString()}`,
              },
            },
          },
        });
      }
      setNewUser(false);
    }
  }, []);

  return (
    <>
      {noIllustration && (
        <div className="checkout-header">
          <div className='img-container'>
          <img src={logo} alt="Hexospark.com" />
          </div>
          
        </div>
      )}
      <div className="d-flex simple-layout">
        {!noIllustration && (
          <div className="left-side">
            <div className="logo-section">
              {isClickable ? (
                <Link to="/">
                  <img src={logo} alt="Hexospark.com" />
                </Link>
              ) : (
                <img src={logo} alt="Hexospark.com" />
              )}
            </div>
            <span className="icons">
              <img src={one} alt="person" className="image image-one" />
              <img src={two} alt="person" className="image image-two" />
              <img src={three} alt="person" className="image image-three" />
            </span>

            <div className="text-section">Personalized email outreach at scale, made easy.</div>
          </div>
        )}

        <div className={`right-side ${noIllustration ? 'full-width' : ''}`}>
          <div className="children-wrapper w-100 d-flex justify-content-center align-items-center">{children}</div>
        </div>
      </div>
      <CookieConsentPopup />
    </>
  );
};

export default SimpleLayout;

import React, {FC, useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {useMutation} from '@apollo/client';
import {SUBSCRIBE_VERIFY} from '../../graphql/mutations';
import {setUserHash, setUserToken} from '../../helpers';
import SimpleLayout from '../../components/layout_simple';
import Button from '../../components/form/button';
import {verified} from '../../images/svgIcons';

const VerifyEmail: FC<{token: string}> = ({token}) => {
  const [verifyMutation, {data, loading}] = useMutation(SUBSCRIBE_VERIFY);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    token && token !== 'index.html' && verifyMutation({variables: {verifyCode: token}});
  }, []);

  useEffect(() => {
    setSuccess(false);
    setErrorMessage('');
    if (data && data.WatchSubscribeOps && data.WatchSubscribeOps.verify) {
      const {error, message} = data.WatchSubscribeOps.verify;
      if (error) {
        setErrorMessage(message);
      } else {
        setSuccess(true);
      }
    }
  }, [data]);

  const searchQuery =
    global &&
    global.window &&
    global.window.location &&
    global.window.location.search &&
    global.window.location.search.split('&');
  let successRegistration = '';
  let error = '';
  let user_token = '';
  let user_hash = '';

  searchQuery &&
    searchQuery.find((item: any) => {
      if (item.indexOf('token') > -1) {
        user_token = item.slice(item.indexOf('=') + 1, item.length);
        user_token && setUserToken(user_token);
      }
      if (item.indexOf('hash') > -1) {
        user_hash = item.slice(item.indexOf('=') + 1, item.length);
        user_hash && setUserHash(user_hash);
      }
      if (item.indexOf('success') > -1) {
        successRegistration = item.slice(item.indexOf('=') + 1, item.length);
      }
      if (item.indexOf('error') > -1) {
        error = item.slice(item.indexOf('=') + 1, item.length);
      }
    });
  // useEffect(() => {
  //   if (successRegistration) {
  //     setTimeout(() => {
  //       window.location.href = `https://hexospark.com/pricing`;
  //     }, 5000);
  //   } else if (success) {
  //     setTimeout(() => {
  //       window.location.href = `https://hexospark.com`;
  //     }, 5000);
  //   }
  // }, [success, successRegistration]);

  useEffect(() => {
    const timer = setTimeout(() => (success || successRegistration) && navigate('/pricing'), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [success, successRegistration]);

  const onClick = () => {
    window.location.replace('https://dash.hexospark.com');
  };

  return (
    <SimpleLayout isClickable>
      <div className="verify-email-section">
        <div className="col-lg-12 text-center" style={{height: '190px'}}>
          {errorMessage || error ? (
            <div className="alert alert-danger">Your verification link is no longer valid</div>
          ) : success || successRegistration ? (
            <>
              <div>{verified}</div>
              <p className="my-3" style={{color: '#00042A', fontWeight: 700, fontSize: '30px'}}>
                Successfully verified
              </p>
              <p className="mb-3" style={{color: '#516077', fontWeight: 400, fontSize: '20px'}}>
                You will be automatically redirected to Pricing page in 5 seconds
              </p>
              <Button btnText="Go to dashboard" width="170" onClick={onClick} />
              {/* <div className="alert alert-success">
                {(success || successRegistration) && <Translate name={'Email has been successfully verified'} />}
              </div> */}
            </>
          ) : (
            loading && (
              <div className="w-100 d-flex">
                <div className="spinner-border spinner-color m-auto" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </SimpleLayout>
  );
};

export default VerifyEmail;
